import axios from 'axios';


const API =  axios.create({
    baseURL: window.ENV.guestAPI
  })

  API.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    return Promise.reject(error);
  });


  



export default API  
  