import React from 'react';
import InjectedCheckoutForm from './UpCardForm'
import {
    withRouter
} from 'react-router-dom'
import './App.css';


class PayByCard extends React.Component {
    componentDidMount() {

    }
    render() {
        return (
                <InjectedCheckoutForm acceptedCards={['visa']} upPartner={this.props.upPartner} finishTran={this.props.finishTran} 
                cardNumber={this.props.cardNumber}
                vari={this.props.vari}
                expiryDate={this.props.expiryDate}
                tranID={this.props.tranID} currency={this.props.currency} amount={this.props.amount}></InjectedCheckoutForm>
        )
    }
}
export default withRouter(PayByCard);
