import React from 'react';
import API from './api'
import { T } from './Lang'
import { PaymentInputsContainer } from 'react-payment-inputs';


import Loader from 'up-design-system-normal/build/components/Loader'
import {
    Notice,
    ButtonPrimary,
    ButtonLayout,
    Input
} from 'up-design-system-normal/build/components/index'

class UpCardForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cardNumber: '',
            expiryDate: '',
            cvc: '',
            submitted: false,
            card_error: false,
            card_number_error: false,
            focus: '',
            vari: '',
            amount: 0,
            disableAmount: false,
            disableCardNumber: false,
            disableExpiryDate: false,
            disableVari: false
        };
    }

    componentDidMount() {
        if (this.props.cardNumber) {
            this.setState({ cardNumber: this.props.cardNumber.match(/.{1,4}/g).join(' '), disableCardNumber: true });
        }
        if (this.props.expiryDate) {
            this.setState({ expiryDate: this.props.expiryDate.match(/.{1,2}/g).join(' / '), disableExpiryDate: true });
        }
        if (this.props.vari) {
            this.setState({ vari: this.props.vari, disableVari: true });
        }
        if (this.props.amount) {
            this.setState({ amount: this.props.amount, disableAmount: true });
        }
        this.setState({ mounted: true })
    }

    canPay = () => {
        return this.state.cardNumber && this.state.cardNumber.length === 19 && this.state.expiryDate
            && this.state.expiryDate.length === 7 && this.state.cvc && this.state.cvc.length === 3
            && ((this.props.upPartner && this.state.vari.length && this.state.amount) || !this.props.upPartner)
            && !this.state.submitted
    }
    wrongCardType = () => {
        return this.state.cardNumber && this.state.cardNumber.length >=7 && !/^6058 95/.test(this.state.cardNumber)
    }
    handleInputFocus = (e) => {
        if (e.target.name === "amount") {
            // target.value = formatCVC(target.value);
            e.target.value = parseFloat(e.target.value).toFixed(2);
            this.setState({ [e.target.name]: e.target.value });
        }
        return false;
    }

    handleInputChange = ({ target }) => {

        if (target.name === "cardNumber") {
            // target.value = formatCreditCardNumber(target.value);
        } else if (target.name === "expiryDate") {
            // target.value = formatExpirationDate(target.value);
        } else if (target.name === "cvc") {
            // target.value = formatCVC(target.value);
        }
        this.setState({ [target.name]: target.value });
    };





    handleSubmit = (ev) => {
        this.setState({ card_error: false });
        // We don't want to let default form submission happen here, which would refresh the page.
        ev.preventDefault();
        this.setState({ submitted: true });
        if (this.props.finishTran) {
            this.props.finishTran({
                vari: this.state.vari, tranID: this.props.tranID, cn: this.state.cardNumber.replace(/ /g, ""), exp: this.state.expiryDate.replace('/', '').replace(/ /g, ""),
                cvc: this.state.cvc, amount: this.state.amount
            })
        }
    };
    render() {
        const cardErrorMessage = () => {
            return new T().translate("PublicPages.Gateway.cardVerifyFailed")
        }
        return (
            this.state.mounted ?
                <form onSubmit={this.handleSubmit} className="card-payment-form" ref={input => { this.myInput = input; }}>

                    {this.state.card_error &&
                        <Notice
                            icon="message-error"
                            iconSpritePath="/sprite.svg"
                            closeSize="medium"
                            closeable="true">
                            <b>{cardErrorMessage()}</b>
                        </Notice>
                    }
                    {this.wrongCardType() &&
                        <div style={{marginBottom:'5px'}}>
                            <b style={{color:'red'}}><T t="PublicPages.Gateway.wrongCardType"></T></b>
                        </div>
                    }
                    <PaymentInputsContainer>
                        {({ meta, getCardNumberProps, getExpiryDateProps, getCVCProps }) => (
                            <div>
                                <div className="form-label">
                                    <label htmlFor="cnumber"><T t="PublicPages.Gateway.card_number"></T>
                                        <span className="form-label__required">&nbsp;*</span>
                                    </label>
                                </div>
                                <input className="input"
                                    autoFocus={!this.state.disableCardNumber}
                                    maxLength="19"
                                    autoComplete="cc-number"
                                    readOnly={this.state.disableCardNumber}
                                    disabled={this.state.disableCardNumber}
                                    {...getCardNumberProps({ onChange: this.handleInputChange })} value={this.state.cardNumber}
                                    placeholder="XXXX XXXX XXXX XXXX"
                                />
                                <div className="form-label">
                                    <label htmlFor="cexp"><T t="PublicPages.Gateway.card_exp"></T>
                                        <span className="form-label__required">&nbsp;*</span>
                                    </label>
                                </div>

                                <input className="input"
                                    autoComplete="cc-exp" {...getExpiryDateProps({ onChange: this.handleInputChange })} value={this.state.expiryDate}
                                    placeholder="MM / RR"
                                    readOnly={this.state.disableExpiryDate}
                                    disabled={this.state.disableExpiryDate}
                                    autoFocus={this.state.disableCardNumber && !this.state.disableExpiryDate}
                                />
                                <div className="form-label">
                                    <label htmlFor="cvcv"><T t="PublicPages.Gateway.card_cvc"></T>
                                        <span className="form-label__required">&nbsp;*</span>
                                    </label>
                                </div>
                                <input className="input"
                                    autoComplete="cc-csc"{...getCVCProps({ onChange: this.handleInputChange })} value={this.state.cvc}
                                    autoFocus={this.state.disableCardNumber && this.state.disableExpiryDate}
                                    placeholder="123" />
                                {this.props.upPartner &&
                                    <div>
                                        <div className="form-label">
                                            <label htmlFor="amount"><T t="PublicPages.Gateway.amount"></T> ({this.props.currency})
                                                <span className="form-label__required">&nbsp;*</span>
                                            </label>
                                        </div>
                                        <input className="input" pattern="^\d*(\.\d{0,2})?$" name="amount" type="number" min="0.1" max="99999" step="0.01"
                                            onChange={this.handleInputChange} onBlur={this.handleInputFocus} value={this.state.amount}
                                            readOnly={this.state.disableAmount}
                                            disabled={this.state.disableAmount}
                                            placeholder="100" />
                                        <div className="form-label">
                                            <label htmlFor="cvcv"><T t="PublicPages.Gateway.vari"></T>
                                                <span className="form-label__required">&nbsp;*</span>
                                            </label>
                                        </div>
                                        <input className="input" name="vari"
                                            readOnly={this.state.disableVari}
                                            disabled={this.state.disableVari}
                                            onChange={this.handleInputChange} value={this.state.vari}
                                            placeholder="1234567890" />
                                    </div>


                                }
                            </div>
                        )}
                    </PaymentInputsContainer>
                    <div id="payment-button">
                        <ButtonLayout style={{ marginTop: '25px' }}>
                            {!this.state.submitted ?
                                <ButtonPrimary isDisabled={!this.canPay() || this.wrongCardType()} submit>
                                    <T t="PublicPages.Gateway.payButton" v={this.state.amount + ' ' + this.props.currency}></T>
                                </ButtonPrimary>
                                : <Loader
                                    id="loader"
                                    isCentered
                                    size="large"
                                />

                            }
                        </ButtonLayout>
                    </div>
                    {this.state.card_number_error &&
                        <span style={{ color: 'red' }}><T t="PublicPages.Gateway.cardWrongNumber"></T></span>
                    }
                </form>
                :
                <div></div>
        );
    }
}

export default UpCardForm;