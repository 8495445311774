import React from 'react';
import {  Cookies  } from 'react-cookie';
import _ from 'lodash';
import {useLocation } from 'react-router-dom';

export function LangDetection(props) {
    let query = new URLSearchParams(useLocation().search);
    const cookies = new Cookies();
    let lang = "sk";
    if (query.get("lang")) {
      cookies.set('lang', query.get("lang"));
      lang = query.get("lang");
    } else {
      if (cookies.get('lang')) {
        lang = cookies.get('lang');
        lang = "sk"
      } else {
        //get browser language and store
        cookies.set('lang', lang);
      }
  
    }
    return (<Lang lang={lang}> {props.children}</Lang>)
  }
  export class Lang extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        lang_loaded: false,
      };
    }
    langLoader = () => {
      console.log('reactloader');
      this.setState({ lang_loaded: true });
    }
    componentDidMount() {
      window.langLoader = this.langLoader;
      var script = document.createElement('script');
      script[(script.innerText === undefined ? "textContent" : "innerText")] = 'function define(obj,callback){ console.log("loading language"); callback(); window.langLoader() }';
      document.documentElement.appendChild(script);
      var addScript = document.createElement('script');
      addScript.setAttribute('src',  '/static/lang/' + this.props.lang +'/Resources.js');
      document.body.appendChild(addScript);
    }
    render() {
      if (this.state.lang_loaded) {
        return <div>
          {this.props.children}
        </div>;
      }
      else {
        return null;
      }
    }
  }
  export class T extends React.Component {
    translate = (key, val,val1,val2) => {
      let s = _.get(window.GexPay.Data.Resources, key)
      if(s){
        return s.replace('{0}', val).replace('{1}', val1).replace('{2}', val2) ;
      }
      else
        return key;
    }
    render() {
      return <span>{this.translate(this.props.t, this.props.v || '',this.props.v1 || '', this.props.v2 || '')}</span>;
    }
  }
  
