import React from 'react';
import API from './api'
import { T } from './Lang'
import {
  withRouter
} from 'react-router-dom'
import './App.css';
import {
  Card,
  CardContent,
  Container,
  Grid,
  GridCol,
  Overlay,
  Section,
  Image,
  Icon,
  Notice,
} from 'up-design-system-normal/build/components/index'
import { GatewayHeader, GatewayFooter } from './GatewayUIComponents'

import Loader from 'up-design-system-normal/build/components/Loader'
import PayByCard from './PayByCard'
import Refund from './Refund'


const gatewayFooterStyle = {
  backgroundColor: '#2B387C',
  padding: '25px'
}
const gatewayFooterLinkStyle = {
  color: '#dcdcdc',
  fontSize: 'smaller'
}
const qrCodeStyle = {
  borderRadius: '5px',
  width: '300px',
  padding: '5px',
  boxShadow: '1px 1px 10px 1px ' + gatewayFooterStyle.backgroundColor
}

class Pay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tranDataLoaded: false,
      showPaymentTools: false,
      showRefundTools:false,
      tranID: '',
      tran: null,
      alreadyVerifiedOrPaid: false,
      stayOnPage: false,
      paymentInProccess: false,
      isRefund: false,
      originalTran: null,
      finishError:false,
      finishErrorText:'',
      isTerminalInactive : false      
    };
  }
  amountWithCurrency = () => {
    if (!this.state.tran) return ""
    return this.state.tran.amount + ' ' + this.state.tran.currency;
  }
  orderStatusMessage = () => {
    if (this.state.alreadyVerifiedOrPaid) {
      if (this.state.stayOnPage) {
        if(this.state.isRefund)
        {
          return { text: new T().translate("PublicPages.Gateway.refund_finished"), succIcon: true }
        }
        else
        {
          return { text: new T().translate("PublicPages.Gateway.payment_finished"), succIcon: true }
        }
      }
      else {
        if(this.state.isRefund)
        {
          return { text: new T().translate("PublicPages.Gateway.orderAlreadyRefunded") + " " + new T().translate("PublicPages.Gateway.redirecting"), succIcon: true }
        }
        else
        {
          return { text: new T().translate("PublicPages.Gateway.orderAlreadyPaid") + " " + new T().translate("PublicPages.Gateway.redirecting"), succIcon: true }
        }
      }
    }
    if (this.state.paymentInProccess) {
      if(this.state.isRefund)
      {
          return { text: new T().translate("PublicPages.Gateway.processing_refund"), loader: true }
      }
      else
          return { text: new T().translate("PublicPages.Gateway.processing_payment"), loader: true }
    }
  }
  finishTran = (data) => {
    let that = this;
    this.setState({ showPaymentTools: false,showRefundTools:false,  paymentInProccess: true,finishError:false })
    API.post('/finish',  data ).then(function (response) {
      let res = response.data;
      if (res.error) {
        that.setState({paymentInProccess: false,finishError:true,finishErrorText:res.error.responseText })
        that.showTranTypeTools()
      }
      else {
        //all ok
        that.setState({tran:res.tran})
        that.afterPaid()
      }
    })
  }
  afterPaid = () => {
    this.setState({ tranDataLoaded: true, showPaymentTools: false, alreadyVerifiedOrPaid: true,finishError:false })
    if (this.state.tran.merchant.backUrl) {
      setTimeout(() => {
        window.location.href = this.state.tran.merchant.backUrl
      }, 1500);
    }
    else {
      this.setState({ stayOnPage: true });
    }
  }
  showTranTypeTools = () => {
    if (this.state.tran.method === 'PAY') {
      this.setState({showPaymentTools: true})
    } else {
      this.setState({showRefundTools: true})
    }
  }
  setPaymentType = () => {
    if (this.state.tran.method === 'REFUND') {
      this.setState({isRefund: true})
    }
  }
  testTerminalStatus = () => {
    let terminalOK = true
    if (this.state.tran.merchant.state === 'DEACTIVE') {
      this.setState({isTerminalInactive: true})
      terminalOK = false
    }
    return terminalOK
  }
  loadOrderInfo = () => {

    let that = this;
    API.post('/tranInfo', { tranID: this.props.match.params.tranID }).then(function (response) {
      let res = response.data;
      that.setState({ tran: res.tran ,originalTran:res.originalTran });
      that.setPaymentType()
      if (res.tran.status === 'PAID' || res.tran.status ==='REFUNDED') {
        that.afterPaid();
      } else {
        if(that.testTerminalStatus()) 
        {
          that.showTranTypeTools();
        }
        that.setState({
          tranDataLoaded: true
        })

      }
    })
  }
  componentDidMount() {
    this.setState({ tranID: this.props.match.params.tranID });
    this.loadOrderInfo();
  }
  render() {
    const finishErrorMessage = () => {
      return <div><div> { new T().translate("PublicPages.Gateway.cardVerifyFailed") } </div> { this.state.finishErrorText} </div>
   }
    const cardPaymentContent = this.state.tranDataLoaded &&
      <Grid className="align-items-center">
        <GridCol className="show-m align-items-middle" size={{ s: 10, m: 6, l: 6, xl: 6 }}>
          <Section size="medium" className="pb-none text-center">
            {this.state.tran.merchant.upPartner && 
            <Image src="/images/UP_partner_Q.svg" />
            }
            {!this.state.tran.merchant.upPartner && 
            <Image src="/images/UP_dejeuner_Q.svg" />
            }
          </Section>
        </GridCol>
        <GridCol id="cardColl" size={{ s: 10, m: 6, l: 6, xl: 6 }}>
          <Section size="medium" className="pb-none">
            <PayByCard finishTran={this.finishTran} upPartner={this.state.tran.merchant.upPartner} tranID={this.props.match.params.tranID} currency={this.state.tran.currency} 
            cardNumber={this.props.location.state.cardNumber}
            vari={this.props.location.state.vari}
            expiryDate={this.props.location.state.expiryDate}
            amount={this.state.tran.amount}></PayByCard>
          </Section>
        </GridCol>
      </Grid>
    return (
      <div>
        <GatewayHeader></GatewayHeader>


        <Overlay
          color="transparent"
          contentClass="no-pad"
        >
          <div style={{ paddingTop: '30px', paddingBottom: '10px' }} id="start">
            <Container>

              <Grid>
                <GridCol size={{ s: 0, m: 1, l: 2, xl: 'auto' }}></GridCol>
                <GridCol size={{ s: 12, m: 10, l: 8, xl: 8 }}>
                  <Card id="card-form" style={{ boxShadow: "4px 4px 10px grey" }}>
                    <CardContent>
                      <Grid className="align-items-center">
                        <GridCol size={{ s: 12, m: 10, l: 10, xl: 10 }}>
                        {this.state.isTerminalInactive &&
                            <Notice
                              icon="message-error"
                              closeSize="medium"
                              closeable="true">
                              <b>{<T t="PublicPages.Gateway.terminalInactive"></T>}</b>
                            </Notice>
                          }

                          {this.state.finishError &&
                            <Notice
                              icon="message-error"
                              closeSize="medium"
                              closeable="true">
                              <b>{finishErrorMessage()}</b>
                            </Notice>
                          }
                          {this.orderStatusMessage() &&
                            <Section>
                              {this.orderStatusMessage().succIcon &&
                                <Icon
                                  name="message-success"
                                  size="large"
                                  className="icon"
                                  color='green'
                                />
                              }
                              {this.orderStatusMessage().loader &&
                                <Loader
                                  id="loader"
                                  isCentered
                                  size="large"
                                />

                              }
                              <Notice
                                closeSize="medium"
                                closeable="false">
                                <b>{this.orderStatusMessage().text}</b>
                              </Notice>
                            </Section>
                          }
                          {!this.state.tranDataLoaded ?
                            <Loader
                              id="loader"
                              isCentered
                              size="large"
                            />
                            :
                            <div style={{ marginTop: '10px' }}>
                              <div>
                                {this.state.showPaymentTools &&
                                  <div>
                                    {cardPaymentContent}
                                  </div>

                                }
                                {this.state.showRefundTools &&
                                  <div>
                                    <Refund orderDate={this.state.originalTran.processed} orderNumber={this.state.tran.orderNumber} tranID={this.state.tranID} finishTran={this.finishTran} amount={this.amountWithCurrency()}></Refund>
                                  </div>
                                }
                              </div>
                              <Section size="medium" style={{fontSize:'12px'}}>
                              { this.state.tran.merchant.upPartner &&
                                <b>{<T t="PublicPages.Gateway.commonTextPartner"></T>}</b>
                              }
                              {!this.state.tran.merchant.upPartner &&
                                <b>{<T t="PublicPages.Gateway.commonTextJK"></T>}</b>
                              }
                              </Section>
                            </div>
                          }
                        </GridCol>
                      </Grid>
                    </CardContent>
                  </Card>
                </GridCol>
                <GridCol size={{ s: 0, m: 1, l: 2, xl: 'auto' }}></GridCol>
              </Grid>
            </Container>
          </div>

        </Overlay>
        <GatewayFooter></GatewayFooter>
      </div>);
  }
}

export default withRouter(Pay);
