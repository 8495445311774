import React from 'react';
import {
    Grid,
    GridCol,
    Image,
    Container,
    Section
    
} from 'up-design-system-normal/build/components/index'

const gatewayFooterStyle = {
    backgroundColor: '#4E5B59',
    padding: '25px'
}
const gatewayHeaderStyle = {
  backgroundColor: '#4E5B59',
}
const gatewayFooterLinkStyle = {
    color: '#dcdcdc',
    fontSize: 'smaller'
}

export function GatewayHeader()
{
    return <div style={gatewayHeaderStyle}>
    <Image style={{ margin: 0 }} src="/images/logo-up.png" />
    </div>
}

export class GatewayFooter extends React.Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "https://seal.godaddy.com/getSeal?sealID=jc6TfH5Njdm4tT7r5y5GtoVQhVth5uqLvV13EhVjro4ooWkgrGAl2UH7CqDp";
    script.async = true;
    document.getElementById("siteseal").appendChild(script);
  }
  render() {
    return <div style={gatewayFooterStyle}>
    <Container>
      <Grid className="align-items-center">
        <GridCol className="align-items-top" size={{ s: 10, m: 10, l: 2, xl: 2 }}>
          <Image style={{ margin: 0 }} src="/images/poweredGP.png" />
        </GridCol>
        <GridCol className="align-self-middle" size={{ s: 10, m: 10, l: 2, xl: 'shrink' }}><a style={gatewayFooterLinkStyle} target="_blank" rel="noopener noreferrer" href="https:\\www.gexpay.com">www.gexpay.com</a></GridCol>
        <GridCol className="align-self-middle" size={{ s: 10, m: 10, l: 'shrink', xl: 'shrink' }}><span style={gatewayFooterLinkStyle}>Tel.:&nbsp;+421&nbsp;915&nbsp;16&nbsp;16&nbsp;16</span></GridCol>
        <GridCol className="align-self-middle" size={{ s: 10, m: 10, l: 2, xl: 'shrink' }}><a style={gatewayFooterLinkStyle} href="mailto:info@gexpay.com">info@gexpay.com</a></GridCol>
      </Grid>
      <Section id="siteseal" size="small"></Section>
      
    </Container>
    </div>
}
}
