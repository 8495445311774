import React from 'react';
import Pay from './Pay'
import {
  BrowserRouter as Router, Route,
  Switch
} from 'react-router-dom';
import './App.css';
import Init from './Init';
import { LangDetection } from './Lang'




class DefaultComponent extends React.Component {
  componentDidMount() {
    window.location.href = window.ENV.websiteURL
  }
  render() {
    return "";
  }
}




class App extends React.Component {

  render() {
    return <LangDetection>
      <div className="App">
        <Router>
          <Switch>
            <Route path="/pay/:tranID">
              <Pay />
            </Route>
            <Route path="/init">
              <Init />
            </Route>
            <Route path="/">
              <DefaultComponent></DefaultComponent>
            </Route>
          </Switch>
        </Router>
      </div>
    </LangDetection>
  }
}

export default App;
