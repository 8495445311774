import React from 'react';
import { T } from './Lang'
import {
    withRouter
} from 'react-router-dom'
import {
    Section,
    ButtonPrimary

} from 'up-design-system-normal/build/components/index'

class Refund extends React.Component {

    constructor(props) {
        super(props);
    }
    componentDidMount() {

    }
    refund = () => {
        if (this.props.finishTran) {
            this.props.finishTran({ tranID: this.props.tranID })
        }
    }

    render() {


        return (
            <Section size="xxlarge">
                <div>
                    <Section size="medium">
                        <b>
                            <T t="PublicPages.Gateway.refundText" v={this.props.orderNumber}
                             v1={new Date(this.props.orderDate).toLocaleDateString('sk-SK') + ' ' + new Date(this.props.orderDate).toLocaleTimeString('sk-SK')} 
                             v2={this.props.amount}></T>
                        </b>
                    </Section>
                </div>
                <ButtonPrimary submit onClick={this.refund}>
                    <T t="PublicPages.Gateway.refundButton" v={this.props.amount}></T>
                </ButtonPrimary>
            </Section>
        );
    }
}

export default withRouter(Refund);
