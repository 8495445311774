import React from 'react';
import Loader from 'up-design-system-normal/build/components/Loader'
import API from './api'
import {
  withRouter, Redirect
} from 'react-router-dom'
import queryString from 'query-string'
import { T } from './Lang'
import {
  Notice,
  Section,
  Container
} from 'up-design-system-normal/build/components/index'

class Init extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      tranLoaded: false,
      tranID: null,
      errorMessage: '',
      cardNumber: '',
      vari: '',
      expiryDate: ''
    };

  }
  componentDidMount() {
    let that = this
    const orderValues = queryString.parse(this.props.location.search)
    if (orderValues.expiryDate) {
      this.setState({ expiryDate: orderValues.expiryDate });
    }
    if (orderValues.vari) {
      this.setState({ vari: orderValues.vari });
    }
    if (orderValues.cardNumber) {
      this.setState({ cardNumber: orderValues.cardNumber });
    }

    API.post('/init', orderValues).then(function (response) {
      let res = response.data;
      if (res.error) {
        console.log(res.error.errorCode, 'errorCode')
        switch (res.error.errorCode) {
          case 'ORIGINAL_TRAN_NOT_PAID':
            that.setState({ errorMessage: new T().translate("PublicPages.Gateway.orderNotPaid") })
            break;
          case 'ORIGINAL_TRAN_NOT_FOUND':
            that.setState({ errorMessage: new T().translate("PublicPages.Gateway.orderNotFound") })
            break;
          case 'INVALID_SIGN':
            that.setState({ errorMessage: new T().translate("PublicPages.Gateway.invalidSign") })
            break;
          default:
        }

      }
      else {
        that.setState({ tranLoaded: true, tranID: res.tranID })
      }
    })
  }
  render() {
    if (this.state.errorMessage) {
      return <Container>
        <Section size="xxlarge"><Notice
          icon="message-error"
          closeSize="medium"
          closeable="true">
          <b>{this.state.errorMessage}</b>
        </Notice></Section>
      </Container>
    }
    if (this.state.tranLoaded === true) {
      return <Redirect to={{
        pathname: '/pay/' + this.state.tranID,
        state: { cardNumber: this.state.cardNumber, vari: this.state.vari, expiryDate: this.state.expiryDate }
      }} />
    }
    if (!this.state.errorMessage) {
      return (
        <Section size="xxlarge">
          <Loader
            id="loader"
            isCentered
            size="large"
          />
        </Section>
      );
    }
  }
}

export default withRouter(Init);
